<template>
  <span ref="box" :class="['item-box', active ? 'active-box' : '']">
    {{ title }}
    <template v-if="active">
      <span class="left-top-corner"></span>
      <span class="right-top-corner"></span>
      <span class="left-bottom-corner"></span>
      <span class="right-bottom-corner"></span>
    </template>
    <svg v-if="active" class="svg-box">
      <defs>
        <path
          id="animationpath"
          :d="`M 2,2 L ${width - 2},0 L ${width - 2},${height - 2} L 2,${
            height - 2
          } z`"
        ></path>
      </defs>
      <circle
        v-for="i in 40"
        :key="i"
        cx="0"
        cy="0"
        :r="i * 0.01 + 1"
        fill="#00fff7"
        :style="`opacity:${i * 0.02}`"
        stroke="none"
      >
        <animateMotion
          :begin="`${1 - i * 0.025}s`"
          dur="6s"
          repeatCount="indefinite"
        >
          <mpath xlink:href="#animationpath" />
        </animateMotion>
      </circle>
    </svg>
  </span>
</template>
<script>
export default {
  props: {
    title: String,
    active: Boolean,
  },
  data() {
    return {
      width: 0,
      height: 40,
    };
  },
  watch: {
    title: {
      handler() {
        this.judgeSize();
      },
    },
  },
  methods: {
    //   判断宽高
    judgeSize() {
      this.width = this.$refs.box.scrollWidth;
    },
  },
  mounted() {
    this.judgeSize();
  },
};
</script>
<style lang='less' scoped>
.item-box {
  display: block;
  width: 100%;
  height: 40px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  .left-top-corner {
    width: 12px;
    height: 8px;
    border-left: 2px solid #00fff7;
    border-top: 2px solid #00fff7;
    position: absolute;
    left: 1px;
    top: 1px;
  }
  .right-top-corner {
    width: 12px;
    height: 8px;
    border-right: 2px solid #00fff7;
    border-top: 2px solid #00fff7;
    position: absolute;
    right: 1px;
    top: 1px;
  }
  .left-bottom-corner {
    width: 12px;
    height: 8px;
    border-left: 2px solid #00fff7;
    border-bottom: 2px solid #00fff7;
    position: absolute;
    left: 1px;
    bottom: 1px;
  }
  .right-bottom-corner {
    width: 12px;
    height: 8px;
    border-right: 2px solid #00fff7;
    border-bottom: 2px solid #00fff7;
    position: absolute;
    right: 1px;
    bottom: 1px;
  }

  .svg-box {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
.active-box {
  color: #00fff7;
}
</style>