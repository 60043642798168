<template>
  <div class="header-bar">
    <div class="sys-info">
      <img src="/imgs/logo.png" alt="" />
      <svg>
        <defs>
          <linearGradient x1="0%" y1="0%" x2="0%" y2="100%" id="MyGradient">
            <stop offset="5%" stop-color="#aff884" />
            <stop offset="95%" stop-color="#32aee6" />
          </linearGradient>
        </defs>
        <text font-size="24" x="10" fill="url(#MyGradient)" y="20">
          粪污资源化利用
        </text>
      </svg>
    </div>
    <div class="top-menu" ref="topMenu">
      <scroll-box direction="x">
        <span
          :class="[
            'menu-item',
            menuList.id == item.id ? 'menu-item-active' : '',
          ]"
          v-for="item in firstLevelMenu"
          :key="item.id"
          @click="cutMenu(item, true)"
        >
          <active-item
            :title="item.name"
            :active="menuList.id == item.id"
          ></active-item>
        </span>
        <div
          style="
            color: #fff;
            position: absolute;
            left: -5px;
            top: 20px;
            background: #00243E;
          "
          class="iconfont"
          slot="left-button"
        >
          &#xe665;
        </div>
        <div
          style="
            color: #fff;
            position: absolute;
            top: 20px;
            right: 0px;
            background: #00243E;
          "
          class="iconfont"
          slot="right-button"
        >
          &#xe665;
        </div>
      </scroll-box>
      <Dropdown trigger="hover" @on-click="cutMenu2">
        <span
          v-if="hiddenMenu.length > 0"
          class="iconfont"
          style="cursor: pointer"
          >&#xe665;</span
        >
        <DropdownMenu slot="list">
          <DropdownItem
            v-for="item in hiddenMenu"
            :key="item.id"
            :name="item.id"
            >{{ item.name }}</DropdownItem
          >
        </DropdownMenu>
      </Dropdown>
    </div>
    <div class="function-pane">
      <span
        class="refresh-btn iconfont icon-shuaxin"
        title="刷新"
        @click="refresh"
      ></span>
      <Dropdown trigger="click">
        <span class="iconfont icon-tiaosepan header-icon" title="主题"></span>
        <DropdownMenu slot="list">
          <span
            @click="() => changeTheme(theme)"
            :style="{ background: theme.color }"
            :class="[
              'theme-item',
              currentClassName == theme.className ? 'current-class' : '',
            ]"
            v-for="(theme, index) in themeClassList"
            :key="index"
          >
          </span>
        </DropdownMenu>
      </Dropdown>
      <span
        class="icon-button"
        @click="actionFullscreen"
        :title="!fullscreen ? '全屏' : '退出全屏'"
      >
        <span
          v-if="!fullscreen"
          class="iconfont icon-quanping header-icon"
        ></span>
        <span v-else class="iconfont icon-quanpingtuichu header-icon"></span>
      </span>
      <Poptip
        trigger="hover"
        transfer
        :content="
          newMessageNumber ? `您有${newMessageNumber}条未读消息` : '暂无新消息'
        "
      >
        <span :class="[newMessageNumber > 0 ? 'message-notice' : '']">
          <Badge :offset="[20, 4]" dot :count="newMessageNumber">
            <span @click="checkTalk" class="msg iconfont">&#xe60c;</span>
          </Badge>
        </span>
      </Poptip>
      <Poptip placement="bottom-end" transfer>
        <Badge :offset="[20, 4]" dot :count="msgCount">
          <span
            class="msg iconfont"
            :title="msgCount > 0 ? `您有${msgCount}条新消息` : '暂无新消息'"
            >&#xe654;</span
          >
        </Badge>
        <div slot="content" class="msgBox">
          <Tabs v-model="msgTab" size="small">
            <TabPane :label="sysLabel" name="sys">
              <p class="noMsg" v-if="sysMessageArr.length === 0">
                暂无新系统消息
              </p>
              <ul v-else>
                <li
                  v-for="(el, i) in sysMessageArr.slice(0, 5)"
                  :key="i"
                  :class="el.unread ? 'actived' : ''"
                >
                  <span v-if="el.unread" class="newIcon">[新]</span>
                  <span v-else class="oldIcon">[已读]</span>
                  <a @click="modalShow(el)"
                    ><span :class="el.unread ? 'newIcon' : ''">{{
                      el.messageTitle
                    }}</span></a
                  >
                </li>
              </ul>
              <li>
                <a @click="checkMore()"><span>查看更多历史消息</span></a>
              </li>
            </TabPane>
            <TabPane :label="noticeLabel" name="notice">
              <p class="noMsg" v-if="noticeMessageArr.length === 0">
                暂无新通知公告
              </p>
              <ul v-else>
                <li
                  v-for="(el, i) in noticeMessageArr.slice(0, 5)"
                  :key="i"
                  :class="el.unread ? 'actived' : ''"
                >
                  <p>
                    <span v-if="el.unread" class="newIcon">[新]</span>
                    <span v-else class="oldIcon">[已读]</span>
                    <a @click="modalShow(el)"
                      ><span :class="el.unread ? 'newIcon' : ''">{{
                        el.messageTitle
                      }}</span></a
                    >
                  </p>
                  <p class="time">{{ el.createTime }}</p>
                </li>
              </ul>
              <li>
                <a @click="checkMore()"><span>查看更多历史消息</span></a>
              </li>
            </TabPane>
          </Tabs>
        </div>
      </Poptip>
      <span class="user-avatar" v-if="user" @click="editInfomatio">
        <img
          :src="
            user.avatar.startsWith('http')
              ? user.avatar
              : user.servicePath + user.avatar
          "
          alt=""
        />
      </span>
      <span
        class="iconfont icon-xiugaimima header-icon"
        title="修改密码"
        @click="ChangePassword"
      ></span>
      <Modal v-model="modal1" title="修改密码" @on-ok="ok" @on-cancel="cancel">
        <Form :label-width="150" ref="form" :model="form" :rules="rules">
          <FormItem label="用户名:" prop="name">
            {{ user.name }}
            <!-- <Input clearable v-model="user.name" style="width: 260px"></Input> -->
          </FormItem>
          <FormItem label="原密码:" prop="password">
            <Input
              clearable
              v-model="form.password"
              placeholder="请输入原密码"
              style="width: 260px"
            ></Input>
          </FormItem>
          <FormItem label="新密码:" prop="newPwd">
            <Input
              :type="password"
              v-model="form.newPwd"
              placeholder="请输入新密码"
              style="width: 260px"
            ></Input>
          </FormItem>
          <FormItem label="确认密码:" prop="rePwd">
            <Input
              password
              :type="password"
              v-model="form.rePwd"
              placeholder="请再次输入密码"
              style="width: 260px"
            ></Input>
          </FormItem>
        </Form>
      </Modal>
      <span
        class="iconfont icon-guanbi header-icon"
        title="退出"
        @click="logout"
      ></span>
      <user-Information v-model="defulteModel" :title="defulteTitle">
      </user-Information>
    </div>

    <Modal v-model="checkMessageModal" title="消息详情" :width="500">
      <Form :label-width="110">
        <FormItem label="消息标题：">
          <span>{{ this.checkInfo.messageTitle }}</span>
        </FormItem>
        <FormItem label="消息内容：">
          <span>{{ this.checkInfo.messageContent }}</span>
        </FormItem>
        <FormItem label="发送时间：">
          <span>{{ this.checkInfo.createTime }}</span>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button @click="cancle()">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import userInformation from "./userInformation";
import ActiveItem from "./ActiveItem.vue";
import scrollBox from "@/components/scrollBox";
import moment from "moment";

export default {
  name: "",
  inject: ["reload"],
  components: {
    userInformation,
    ActiveItem,
    scrollBox,
  },
  data() {
    return {
      form: {
        password: null,
        // 旧密码
        newPwd: null,
        rePwd: null,
        // 新密码
      },
      password: "password",
      defulteform: {
        password: null,
        // 旧密码
        newPwd: null,
        rePwd: null,
        // 新密码
      },

      rules: {
        password: [{ required: true, message: "请输入原密码" }],
        // birthday: [{ required: true, type: "date", message: "请选择出生年月" }],
        newPwd: [
          { required: true, message: "请输入新密码" },
          {
            validator(rule, value, callback) {
              if (
                !/^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/.test(
                  value
                )
              ) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "密码最少8位，包括至少大小写字母，数字，特殊字符",
          },
        ],
        rePwd: [
          { required: true, message: "请再次输入确认密码" },
        ],
      },
      modal1: false,
      fullscreen: false,
      defulteModel: false,
      defulteTitle: "",
      totalWidth: document.body.clientWidth,
      length: 0,
      showMenu: [],
      hiddenMenu: [],

      sysMessageArr: [],

      noticeMessageArr: [],
      checkMessageModal: false,
      checkInfo: {},
      msgTab: "sys",
      sysLabel: (c) => {
        let sysCount = this.sysMessageArr.filter((el) => el.unread).length;
        return (
          <span>
            系统消息&nbsp;&nbsp;
            {sysCount > 0 && <Badge count={sysCount}></Badge>}
          </span>
        );
      },
      noticeLabel: (c) => {
        let noticeCount = this.noticeMessageArr.filter(
          (el) => el.unread
        ).length;
        return (
          <span>
            通知公告&nbsp;&nbsp;
            {noticeCount > 0 && <Badge count={noticeCount}></Badge>}
          </span>
        );
      },
    };
  },
  watch: {
    sysMsg(n) {
      if (n) {
        this.sysMessageArr.unshift({
          ...n,
          unread: true,
          createTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        });
      }
    },
    $route(n) {
      let menuList = this.checkMenuList(this.firstLevelMenu, n.name);
      if (menuList.id != this.menuList.id) {
        this.$store.commit("setMenuList", menuList);
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      currentClassName: (state) => state.baseInfo.themeClass,
      themeClassList: (state) => state.baseInfo.themeClassList,
      firstLevelMenu: (state) => state.permission.firstLevelMenu,
      menuList: (state) => state.permission.menuList,
      sysMsgArr: (state) => state.msg.sysMsgArr,
      sysMsg: (state) => state.msg.sysMsg,
      // 新消息数量
      newMessageNumber: (state) => state.rongIMLib.newMessageNumber,
    }),
    navWidth() {
      return this.totalWidth - 284 - 250 - 16;
    },
    msgCount() {
      return this.sysMessageArr.filter((el) => el.unread).length;
    },
  },
  methods: {
    //修好密码
    ChangePassword() {
      this.modal1 = true;
      console.log(this.user);
    },
    ok() {
      this.$refs.form.validate().then((res) => {
        // if (!res) return;
        let params = this.form;
        this.modal1 = true;
        console.log(params);
        if (params.newPwd == params.rePwd) {
          this.modal1 = true;
          console.log(params.newPwd, "==this.rePwd");
          this.$post(this.$api.USER1.EDIT, params)
            .then(() => {
              this.$Message.success("修改成功！");
              this.modal1 = false;
              this.$emit("input", false);
              // this.$emit("refrestList");
            })
            .catch((e) => {
              console.log(e);
              this.modal1 = true;
              this.form = { ...this.defulteform };
            });
        } else {
          this.$Message.info("两次输入不一致，请重新输入");
          this.form.rePwd = "";
          this.form.newPwd = "";
          this.modal1 = true;
          // this.form={...this.defulteform}
        }
      });
    },
    cancel() {
      this.$Message.info("您取消了修改密码");
      this.form = { ...this.defulteform };
      this.modal1 = false;
    },
    // 打开聊天框
    checkTalk() {
      this.$store.dispatch("startTalk");
    },
    //修改个人信息
    editInfomatio() {
      this.defulteModel = true;
      this.defulteTitle = "修改个人信息";
    },
    //全屏
    actionFullscreen() {
      if (!this.fullscreen) {
        if (document.body.requestFullscreen) {
          document.body.requestFullscreen();
        } else if (document.body.mozRequestFullScreen) {
          document.body.mozRequestFullScreen();
        } else if (document.body.webkitRequestFullscreen) {
          document.body.webkitRequestFullscreen();
        } else if (document.body.msRequestFullscreen) {
          document.body.msRequestFullscreen();
        }
      } else {
        if (document.exitFullScreen) {
          document.exitFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    changeTheme(theme) {
      this.$store.dispatch("changeTheme", theme.className);
    },
    logout() {
      this.$store.dispatch("clearApp").then(() => {
        this.$router.push({ name: "login" });
        location.reload();
      });
    },

    //刷新
    refresh() {
      this.reload();
    },

    cutMenu(menu, flag) {
      this.$store.commit("setMenuList", menu);
      if (menu.levels == 2) {
        this.$router.push(
          { name: menu.url },
          () => {},
          () => {}
        );
      } else {
        if (!flag) return;
        let routeName = this.getRouteName(menu.children);
        this.$router.push(
          { name: routeName },
          () => {},
          () => {}
        );
      }
    },
    getRouteName(menus) {
      let url = "";
      if (menus[0].levels == 2) {
        url = menus[0].url;
        return url;
      }
      if (menus[0].children && menus[0].children.length > 0) {
        let subUrl = this.getRouteName(menus[0].children);
        if (subUrl) {
          url = subUrl;
        }
      }
      return url;
    },
    cutMenu2(id) {
      let menu = this.hiddenMenu.filter((item) => item.id == id)[0];
      this.$store.commit("setMenuList", menu);
    },
    checkMenuList(menu, name) {
      let result = "";
      menu.forEach((item) => {
        if (item.url == name) return (result = item);
        if (item.children && item.children.length > 0) {
          let subResult = this.checkMenuList(item.children, name);
          if (subResult) {
            result = item;
          }
        }
      });
      return result;
    },

    //显示模态窗
    modalShow(row) {
      row.unread = false;
      this.checkMessageModal = true;
      this.checkInfo = row;
    },
    cancle() {
      this.checkMessageModal = false;
      this.checkInfo = {};
    },
    checkMore() {
      this.$router.push(
        { name: "msg" },
        () => {},
        () => {}
      );
    },
  },
  mounted() {
    this.$store.dispatch("changeTheme");
    let menuList = this.checkMenuList(this.firstLevelMenu, this.$route.name);
    if (!menuList) return;
    this.cutMenu(menuList, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="less" scoped>
.header-bar {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #00243E;
  .sys-info {
    width: 230px;
    height: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #fff;
    img {
      width: 40px;
      height: 27px;
    }
    // p {
    //   font-size: 18px;
    //   color: #fff;
    //   padding-left: 10px;
    // }
    svg {
      height: 27px;
      width: 210px;
    }
  }
  .top-menu {
    flex-grow: 1;
    flex-shrink: 1;
    text-align: center;
    padding-left: 16px;
    height: 100%;
    overflow: hidden;
    .menu-item {
      display: inline-block;
      height: 100%;
      line-height: 61px;
      cursor: pointer;
      margin: 0 15px;
      font-size: 16px;
      color: #fff;
    }
  }
  .function-pane {
    height: 100%;
    line-height: 61px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 16px;
    color: #fff;
    .message-notice {
      animation: message-notice-bling 1s linear infinite;
    }
    .header-icon {
      font-size: 24px;
      cursor: pointer;
      margin-left: 20px;
    }
    .user-avatar {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin-left: 20px;
      border-radius: 50%;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .theme-item {
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      margin-left: 8px;
      cursor: pointer;
    }
    .current-class {
      box-shadow: 0 0 1px 2px #999;
    }
    .msg {
      font-size: 24px;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
@keyframes message-notice-bling {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>