<template>
  <!-- 订单管理 -->
  <div class="outer-page">
    <Modal
      :title="title"
      :value="value"
      @input="(val) => $emit('input', val)"
      :width="800"
      @on-visible-change="modalShow"
    >
      <Form :label-width="100" ref="form" :model="form" :rules="rules">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
          "
        >
          <FormItem
            :show-message="false"
            style="margin-bottom: 0px"
            :label-width="0"
          >
            <upload-image :max="1" v-model="form.avatar"> </upload-image>
            <span style="font-size: 16px; font-weight: bold; padding-top: 6px"
              >头像</span
            >
          </FormItem>
        </div>
        <Row>
          <Col span="12">
            <FormItem label="姓名" prop="name">
              <Input
                clearable
                placeholder="请输入姓名"
                v-model="form.name"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="性别" prop="sex">
              <Select v-model="form.sex" style="width: 260px" clearable>
                <Option :value="1">男</Option>
                <Option :value="0">女</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="所属部门" prop="deptName">
              <Input
                clearable
                placeholder="暂无部门"
                :disabled="true"
                v-model="form.deptName"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="员工工号" prop="account">
              <Input
                clearable
                placeholder="请输入员工工号"
                v-model="form.account"
                :disabled="true"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="电话" prop="phone">
              <Input
                clearable
                v-model="form.phone"
                style="width: 260px"
                maxlength="11"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="出生日期" prop="birthday">
              <DatePicker
                type="date"
                placeholder="请选择出生年月"
                v-model="form.birthday"
                style="width: 260px"
              ></DatePicker>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="邮箱" prop="email">
              <Input
                clearable
                v-model="form.email"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="地址" prop="address">
              <Input
                clearable
                v-model="form.address"
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => $emit('input', false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import until from "../../common/util";
export default {
  name: "",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      config: {
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },

      form: {
        sex: "1", //性别
        account: "", //员工工号
        name: "", //员工姓名
        avatar: "", //头像
        birthday: "", //生日
        phone: "", //联系电话
        email: "", //邮箱
        deptId: "", //部门id
        deptName: "", //部门id
        id: "", //编辑用户id
        address: "", //地址
      },
      rules: {
        name: [{ required: true, message: "请输入姓名" }],
        birthday: [{ required: true, type: "date", message: "请选择出生年月" }],
        phone: [{ required: true, message: "请输入联系电话" }],
      },
    };
  },
  methods: {
    //提交数据
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = this.form;
        params.birthday = moment(this.form.birthday).format("YYYY-MM-DD");
        this.$post(this.$api.USER.EDIT, params)
          .then(() => {
            this.$Message.success("修改成功！");
            this.defulteModel = false;
            this.$emit("input", false);
            // this.$emit("refrestList");
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    modalShow(visible) {
      if (visible) {
        this.getInfomation();
      } else {
        this.form = {
          userId: "",
          name: "",
          account: "",
          noValidPeriod: "",
        };
        this.$refs.form.resetFields();
      }
    },
    //获取用户信息
    getInfomation() {
      return new Promise((resolve, reject) => {
        if (
          localStorage.userId &&
          localStorage.companyNo &&
          localStorage.token
        ) {
          this.$post(this.$api.USER.INFO, {
            id: localStorage.userId,
          })
            .then((res) => {
              this.form.sex = res.sex; //性别
              this.form.account = res.account; //员工工号
              this.form.name = res.name; //员工姓名
              this.form.avatar = res.avatar; //头像
              this.form.birthday = res.birthday; //生日
              this.form.phone = res.phone; //联系电话
              this.form.email = res.email; //邮箱
              this.form.deptId = res.deptId; //部门id
              this.form.deptName = res.deptName; //部门名称
              this.form.id = res.id; //编辑用户id
              this.form.address = res.address; //地址
            })
            .catch((e) => reject(e));
        } else {
          reject();
        }
      });
    },
  },
  mounted() {},
  watch: {
    value(val) {
      if (val) {
      } else {
        //   执行重置数据操作
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>